import React from "react";
import "./HeaderDrawer.scss";
import { Drawer, Typography, Button, Badge, Avatar } from "antd";
import {
  CloseOutlined,
  UserOutlined,
  HeartOutlined,
  ExportOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthError } from "../../../../features/auth/authSlice";

export default function HeaderDrawer({
  token,
  isOpened,
  setIsOpened,
  setOpenLogin,
  setOpenRegister,
  setIsLogOutModalOpened,
  isMobile,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, info, error } = useSelector((state) => state?.auth);
  const { shop, loading: shopLoading } = useSelector(
    (state) => state?.shopDetails
  );
  const dispatch = useDispatch();

  return (
    <Drawer
      className="appHeaderDrawer-cont"
      placement="left"
      title={
        shopLoading ? (
          ""
        ) : shop?.name && shop?.is_name_visible_on_shop ? (
          <Typography.Text
            className="appHeaderDrawer-cont__title"
            style={{
              color: token?.Typography?.mainColorText,
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpened(false);
              navigate("/");
            }}>
            {shop?.name}
          </Typography.Text>
        ) : (
          <Button
            type="text"
            icon={
              <HomeOutlined
                style={{
                  color: token?.colorPrimary,
                  fontSize: isMobile ? "24px" : "32px",
                }}
              />
            }
            onClick={() => {
              setIsOpened(false);
              navigate("/");
            }}
          />
        )
      }
      open={isOpened}
      closeIcon={
        <CloseOutlined
          style={{
            color: token?.icons?.whiteIcon,
            fontSize: "24px",
          }}
        />
      }
      onClose={() => {
        setIsOpened(false);
      }}
      width={900}
      styles={{
        header: {
          borderBottom: `1px solid ${token?.colorPrimary}`,
        },
      }}
      style={{
        backgroundColor: token?.Drawer?.colorBgDrawer,
      }}>
      {isAuthenticated ? (
        <div className="appHeaderDrawer-cont__body">
          <div
            className="appHeaderDrawer-cont__body__avatarWrapper"
            onClick={() => {
              setIsOpened(false);
              navigate("profile");
            }}>
            <Badge
              size="small"
              dot
              styles={{
                indicator: {
                  background: token?.colorPrimary,
                },
              }}>
              <Avatar
                shape="square"
                style={{
                  backgroundColor: token?.Card?.colorBgCard,
                }}
                size={24}
                icon={
                  <UserOutlined
                    style={{
                      color:
                        location?.pathname === "/profile"
                          ? token?.colorPrimary
                          : token?.icons?.whiteIcon,
                      fontSize: "18px",
                    }}
                  />
                }
              />
            </Badge>
            <Typography.Text
              className="appHeaderDrawer-cont__body__text"
              style={{
                color:
                  location?.pathname === "/profile"
                    ? token?.Typography?.colorPrimaryText
                    : token?.Typography?.mainColorText,
              }}>
              {info?.first_name ? info?.first_name : ""}
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpened(false);
              navigate("favorites");
            }}>
            <Button
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingLeft: "0px",
              }}
              icon={
                <HeartOutlined
                  style={{
                    color:
                      location?.pathname === "/favorites"
                        ? token?.colorPrimary
                        : token?.icons?.whiteIcon,
                    fontSize: "24px",
                  }}
                />
              }
              type="text">
              <Typography.Text
                className="appHeaderDrawer-cont__body__text"
                style={{
                  color:
                    location?.pathname === "/favorites"
                      ? token?.Typography?.colorPrimaryText
                      : token?.Typography?.mainColorText,
                }}>
                Favourites
              </Typography.Text>
            </Button>
          </div>

          <div className="appHeaderDrawer-cont__body__authWrapper">
            <Button
              type="text"
              icon={
                <ExportOutlined
                  style={{ fontSize: "24px", color: token?.colorError }}
                />
              }
              onClick={() => {
                setIsOpened(false);
                setIsLogOutModalOpened(true);
              }}>
              <Typography.Text
                className="appHeaderDrawer-cont__body__text"
                style={{
                  color: token?.colorError,
                }}>
                Log Out
              </Typography.Text>
            </Button>
          </div>
        </div>
      ) : (
        <div className="appHeaderDrawer-cont__unAuth-body">
          <Button
            onClick={() => {
              setIsOpened(false);
              setOpenLogin(true);
              if (error) {
                dispatch(resetAuthError());
              }
            }}>
            <Typography.Text
              style={{
                color: token?.Typography?.colorPrimaryText,
              }}>
              Log in
            </Typography.Text>
          </Button>
          <Button
            onClick={() => {
              setIsOpened(false);
              setOpenRegister(true);
              if (error) {
                dispatch(resetAuthError());
              }
            }}
            type="primary">
            Sign Up
          </Button>
        </div>
      )}
    </Drawer>
  );
}
