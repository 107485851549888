import React, { useRef, useState } from "react";
import "./ResetPassword.scss";
import { theme, Layout, Typography, Form, Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetPassword } from "../../features/forgetPassword/resetPasswordActions";

const { Content } = Layout;

export default function ResetPassword() {
  const { useToken } = theme;
  const { token } = useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const openMessage = (type, text) => {
    messageApi.open({
      type: type,
      content: text,
      duration: 3,
    });
  };
  const { user_id, token: user_token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const setPassword = (params) => {
    setLoading(true);
    dispatch(resetPassword(params))?.then((res) => {
      if (res?.type === "reset_password/fulfilled") {
        setTimeout(() => {
          setError("");
          setLoading(false);
          navigate("/login");
        }, 1000);
      } else if (res?.type === "reset_password/rejected") {
        if (
          typeof res?.payload?.password === "string" ||
          Array?.isArray(res?.payload?.password)
        ) {
          setError(res?.payload?.password);
          formRef?.current?.setFields([
            {
              name: "password", // Field name you want to set the error for
              errors: Array?.isArray(res?.payload?.password)
                ? res?.payload?.password
                : [res?.payload?.password], // Assuming your error has a 'message' field
            },
          ]);
        } else {
          setError("");
        }
      }
      if (!(typeof res?.payload?.password === "string")) {
        openMessage(res?.payload?.status, res?.payload?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      {contextHolder}
      <Content
        className="resetPassword-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <div className="resetPassword-cont__wrapper">
          <Typography.Text
            className="resetPassword-cont__wrapper__title"
            style={{
              color: token?.Typography?.colorPrimaryText,
            }}>
            Reset Password
          </Typography.Text>
          <Form
            className="resetPassword-cont__wrapper__form"
            ref={formRef}
            layout="vertical"
            onFinish={(values) => {
              delete values?.confirm;
              setPassword({
                ...values,
                token: user_token,
                uid: user_id,
              });
            }}>
            <Form.Item
              className="resetPassword-cont__wrapper__form__formItem"
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              label={<Typography.Text>Password</Typography.Text>}
              colon={false}>
              <Input.Password
                placeholder="Password"
                size="large"
                onChange={() => {
                  if (error) {
                    setError("");
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              className="resetPassword-cont__wrapper__form__formItem"
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              label={<Typography.Text>Confirm Password</Typography.Text>}
              colon={false}>
              <Input.Password placeholder="Confirm Password" size="large" />
            </Form.Item>
            <Button
              type="primary"
              size="large"
              loading={loading}
              disabled={error}
              onClick={() => {
                formRef?.current?.submit();
              }}>
              Set Password
            </Button>
          </Form>
        </div>
      </Content>
    </>
  );
}
