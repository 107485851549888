import React, { useEffect, useState } from "react";
import "./ProductDrawer.scss";
import {
  theme,
  Drawer,
  Typography,
  Card,
  Image,
  Button,
  Divider,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useCard from "../../hooks/useCard";
import Counter from "../Counter/Counter";
import logo from "../../assets/CanaSaleLogo.svg";
import Utils from "../../utils";

export default function ProductDrawer({ isDrawerOpened, close, product }) {
  const { useToken } = theme;
  const { token } = useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const openSuccess = () => {
    messageApi.open({
      type: "success",
      content: "Product added to cart",
    });
  };
  const { addToCard } = useCard();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (product?.variants?.length) {
      const first_variant_count_gt0 = Utils?.findVariantCountGt0(
        product?.variants
      );
      setSelectedVariant(first_variant_count_gt0);
    } else {
      setSelectedVariant(null);
    }
  }, [product]);

  return (
    <>
      {contextHolder}
      <Drawer
        className="productDrawer-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}
        destroyOnClose={true}
        placement="right"
        width={900}
        title={
          <Typography.Text
            className="productDrawer-cont__title"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Add to Cart
          </Typography.Text>
        }
        open={isDrawerOpened}
        closeIcon={
          <CloseOutlined
            style={{
              color: token?.icons?.whiteIcon,
              fontSize: "24px",
            }}
          />
        }
        onClose={() => {
          if (typeof close === "function") {
            close();
            setCount(1);
            setSelectedVariant(null);
          }
        }}>
        <Card
          key={product?.key}
          className="productDrawer-cont__card"
          style={{
            border: "none",
            backgroundColor: token?.Card?.colorBgCard,
          }}
          styles={{
            body: {
              width: "100%",
              display: "flex",
              gap: "12px",
              padding: "8px",
            },
          }}>
          <Image
            src={product?.picture_url ? product?.picture_url : logo}
            width="56px"
            height="56px"
          />
          <div className="productDrawer-cont__card__descriptionCont">
            <Typography.Text
              className="productDrawer-cont__card__descriptionCont__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              {product?.name ? product?.name : ""}
            </Typography.Text>
            {product?.sale && selectedVariant?.sale_price && selectedVariant ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}>
                <Typography.Text
                  key={selectedVariant?.id ? selectedVariant?.id : ""}
                  className="productDrawer-cont__card__descriptionCont__text"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}
                  delete>
                  {selectedVariant?.price
                    ? Utils?.roundNumber(
                        count
                          ? count * selectedVariant?.price
                          : selectedVariant?.price
                      )
                    : ""}
                </Typography.Text>
                <Typography.Text
                  className="productDrawer-cont__card__descriptionCont__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}
                  key={product?.sale?.id}>
                  {selectedVariant?.price && selectedVariant?.sale_price
                    ? Utils?.roundNumber(
                        count
                          ? count * selectedVariant?.sale_price
                          : selectedVariant?.sale_price
                      )
                    : ""}
                </Typography.Text>
              </div>
            ) : (
              <Typography.Text
                className="productDrawer-cont__card__descriptionCont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {selectedVariant?.price
                  ? Utils?.roundNumber(
                      count
                        ? selectedVariant?.price * count
                        : selectedVariant?.price
                    )
                  : ""}
              </Typography.Text>
            )}
            <div className="productDrawer-cont__card__descriptionCont__weightCont">
              <Typography.Text
                className="productDrawer-cont__card__descriptionCont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                Select Weight
              </Typography.Text>
              <div className="productDrawer-cont__card__descriptionCont__weightCont__btnWrapper">
                {product?.variants?.map((variant) => (
                  <Button
                    className="productDrawer-cont__card__descriptionCont__weightCont__btnWrapper__weight"
                    disabled={variant?.count <= 0}
                    style={{
                      borderColor:
                        variant?.id?.toString() ===
                        selectedVariant?.id?.toString()
                          ? token?.colorPrimary
                          : "",
                    }}
                    key={variant?.id}
                    onClick={() => {
                      if (variant?.count > 0) {
                        setSelectedVariant(variant);
                      }
                    }}>
                    <Typography.Text
                      className="productDrawer-cont__card__descriptionCont__text"
                      style={{
                        color:
                          variant?.id?.toString() ===
                          selectedVariant?.id?.toString()
                            ? token?.colorPrimary
                            : "",
                      }}>
                      {variant?.name && variant?.unit
                        ? Utils?.showProductDetail(variant)
                        : ""}
                    </Typography.Text>
                  </Button>
                ))}
              </div>
            </div>
            <div className="productDrawer-cont__card__descriptionCont__countCont">
              <Typography.Text
                className="productDrawer-cont__card__descriptionCont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                Quantity
              </Typography.Text>
              <Counter
                count={count}
                onCauntChnage={(val) => {
                  setCount(val);
                }}
                style={{
                  padding: "0",
                }}
              />
            </div>
          </div>
        </Card>
        <Divider className="productDrawer-cont__divider" />
        <Button
          type="primary"
          block
          disabled={!selectedVariant}
          onClick={() => {
            addToCard(selectedVariant, count, product);
            openSuccess();
          }}>
          Add to Cart
        </Button>
      </Drawer>
    </>
  );
}
