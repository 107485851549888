import React from "react";
import "./ProfileHeader.scss";
import { Button, Typography, Badge, Avatar } from "antd";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

export default function ProfileHeader({ token, resetVariables }) {
  const navigate = useNavigate();

  return (
    <div className="profileHeader-cont">
      <div className="profileHeader-cont__header">
        <Button
          type="text"
          size="small"
          icon={
            <ArrowLeftOutlined
              style={{
                fontSize: "24px",
                color: token?.icons?.whiteIcon,
              }}
            />
          }
          onClick={() => {
            navigate(-1);
            resetVariables();
          }}
        />
        <Typography.Text
          className="profileHeader-cont__header__title"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Profile
        </Typography.Text>
      </div>
      <Badge
        dot
        styles={{
          indicator: {
            background: token?.colorPrimary,
          },
        }}
        classNames={{
          indicator: "profileHeader-cont__badge-indicator",
        }}
        offset={[-15, 15]}>
        <Avatar
          shape="circle"
          style={{
            backgroundColor: token?.Card?.colorBgCard,
          }}
          size={120}
          icon={
            <UserOutlined
              style={{
                color: `${token?.icons?.whiteIcon}`,
                fontSize: "72px",
              }}
            />
          }
        />
      </Badge>
    </div>
  );
}
