import React, { useState } from "react";
import "./ProductCard.scss";
import { theme, Card, Image, Button, Rate, Typography, message } from "antd";
import { ReactComponent as StarIcon } from "../../assets/startIcon.svg";
import { HeartOutlined } from "@ant-design/icons";
import useWindowWidth from "../../hooks/useWindowWidth";
import product_logo from "../../assets/logo_product.svg";
import useCard from "../../hooks/useCard";
import Utils from "../../utils";

export default function ProductCard({
  product,
  navigate,
  haveDrawer,
  openDrawer,
}) {
  const { useToken } = theme;
  const { token } = useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const openSuccess = () => {
    messageApi.open({
      type: "success",
      content: "Product added to cart",
    });
  };
  const { addToCard } = useCard();
  const { width } = useWindowWidth();
  const [addedVariant, setAddedVariant] = useState(null);
  const parentCategory = Utils?.findParentCategory(product);

  return (
    <>
      {contextHolder}
      <Card
        key={`${product?.id}`}
        id={`${product?.id}`}
        className="productCard"
        hoverable
        style={{
          border: "none",
          backgroundColor: token?.Card?.colorBgCard,
          height: "100%",
          maxHeight: width > 2000 ? "373px" : "auto",
        }}
        styles={{
          body: {
            padding: "0px",
            width: "100%",
            height: "100%",
          },
          cover: {
            width: "100%",
            height: "170px",
          },
        }}
        cover={
          <div
            className="productCard__coverWrapper"
            style={
              !product?.picture_url
                ? {
                    background: token?.product_default_image_bg,
                    borderTopRightRadius: "8px",
                    borderTopLeftRadius: "8px",
                  }
                : null
            }>
            <Image
              style={
                !product?.picture_url
                  ? {
                      width: "64px",
                      height: "38px",
                    }
                  : {
                      width: "100%",
                      height: "170px",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                    }
              }
              alt="example"
              src={product?.picture_url ? product?.picture_url : product_logo}
              preview={false}
            />
            <Button
              className="productCard__coverWrapper__btn"
              type="text"
              size="small"
              icon={
                <HeartOutlined
                  style={{
                    color: token?.icons?.colorPrimaryIcon,
                    fontSize: "24px",
                  }}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                console.log("heart clicked");
              }}
            />
          </div>
        }
        onClick={() => {
          if (typeof navigate === "function") {
            navigate();
          }
        }}>
        <div className="productCard__body">
          <Typography.Text className="productCard__body__text">
            Category: {parentCategory ? parentCategory?.name : ""}
          </Typography.Text>
          <Typography.Text
            className="productCard__body__bigText"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            {product?.name ? product?.name : ""}
          </Typography.Text>
          <div className="productCard__body__rateWrapper">
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <Rate
                allowHalf
                value={product?.rate ? product?.rate : 0}
                character={
                  <StarIcon
                    style={{
                      fontSize: "12px",
                    }}
                  />
                }
                count={5}
                style={{
                  fontSize: "12px",
                }}
                onChange={(rate) => {
                  console.log("Rate", rate);
                }}
              />
            </div>

            <Typography.Text className="productCard__body__text">
              {product?.rate ? product?.rate : 0}
            </Typography.Text>
          </div>
          <Typography.Text className="productCard__body__text">
            {`Thc : ${
              product?.cannabinoids?.thc ? `${product?.cannabinoids?.thc}%` : ""
            }`}
          </Typography.Text>
          <div className="productCard__body__variantWrapper">
            {Utils?.getMinandMaxVariants(
              product?.variants?.length ? product?.variants : []
            )?.map((variant) => {
              const currentTime = Date.now(); // Get current time in milliseconds
              const randomNumber = Math.floor(Math.random() * 1000) + 1; // Generate random number between 1 and 1000
              const uniqueNumber = currentTime + randomNumber;
              return product?.sale && variant?.sale_price ? (
                <Typography.Text
                  className="productCard__body__text"
                  key={variant?.id}>
                  <Typography.Text
                    className="productCard__body__text"
                    key={product?.sale?.id}
                    style={{
                      color: token?.Typography?.colorPrimaryText,
                    }}
                    delete>
                    {variant?.price
                      ? `${Utils?.roundNumber(variant?.price)}`
                      : "$"}
                  </Typography.Text>
                  {variant?.sale_price
                    ? ` ${
                        variant?.name && variant?.unit
                          ? `${Utils?.showProductDetail(variant)} - `
                          : ""
                      } ${Utils?.roundNumber(variant?.sale_price)}`
                    : "$"}
                </Typography.Text>
              ) : (
                <Typography.Text
                  className="productCard__body__text"
                  key={variant?.id ? variant?.id : uniqueNumber}>
                  {variant?.price
                    ? `${
                        variant?.name && variant?.unit
                          ? `${Utils?.showProductDetail(variant)} - `
                          : ""
                      } ${Utils?.roundNumber(variant?.price)}`
                    : "$"}
                </Typography.Text>
              );
            })}
          </div>
          <Button
            block
            style={{
              marginTop: "auto",
            }}
            disabled={
              product?.variants?.length === 1 &&
              addedVariant?.id?.toString() ===
                product?.variants[0]?.id?.toString()
            }
            onClick={(e) => {
              e.stopPropagation();
              if (product?.variants?.length === 1) {
                setAddedVariant(product?.variants[0]);
                addToCard(product?.variants[0], 1, product);
                openSuccess();
                setTimeout(() => {
                  setAddedVariant(null);
                }, 1500);
              } else if (typeof navigate === "function" && !haveDrawer) {
                navigate();
              } else if (typeof openDrawer === "function" && haveDrawer) {
                openDrawer(product);
              }
            }}>
            Add to Cart
          </Button>
        </div>
      </Card>
    </>
  );
}
