import React, { useEffect, useRef, useState } from "react";
import "./CartOrderDetails.scss";
import { Button, Card, Typography, Divider, Select, Input, Form } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Captcha, ShippingSelect } from "../../../../components";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  makeOrder,
  makeOrderForUnAuthUser,
} from "../../../../features/order/orderActions";
import { setCard } from "../../../../features/card/cardSlice";
import {
  postPatientShippingAddress,
  patchPatientShippingAddress,
  getPatientDetails,
  patchPatient,
} from "../../../../features/patient/patientActions";
import { getCaptcha } from "../../../../features/captcha/captchaActions";
import { getPromoCodes } from "../../../../features/promoCodes/promoCodeActions";
import { resetPatientError } from "../../../../features/patient/patientSLice";
import Utils from "../../../../utils";
import { PickUpPoint } from "../../../../modals";

const ADDRESS_ERROR = "Please add a pickup address before proceeding.";

export default function CartOrderDetails({
  token,
  isMobile,
  orderCalculation,
  openNotificationWithIcon,
  haveCartCredentials,
  formRef,
  orderLoading,
  setOrderLoading,
  selectedPromoCode,
  setSelectedPromoCode,
  isAuthenticated,
}) {
  const orderFormRef = useRef(null);
  const {
    patient,
    shipping_addresses: { results: shipping_addresses },
    loading,
    error,
  } = useSelector((state) => state?.patient);
  const { items } = useSelector((state) => state?.card);
  const {
    codes: { results: promoCodes },
    loading: promoCodesLoading,
  } = useSelector((state) => state?.promoCodes);
  const navigate = useNavigate();
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [pick_up_point_opened, set_pick_up_point_opened] = useState(false);
  const [addressError, setAddressError] = useState("");
  const dispatch = useDispatch();
  const addresses = [
    {
      id: "current",
      address1: patient?.address ? patient?.address : "",
      state: patient?.state ? patient?.state : "",
      city: patient?.city ? patient?.city : "",
      zipcode: patient?.zipcode ? patient?.zipcode : "",
      address2: patient?.address2 ? patient?.address2 : "",
    },
    ...(shipping_addresses || []),
  ];
  const [initialAddress, setInitialAddress] = useState(undefined);
  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState(undefined);
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaLoading, setIsCaptchaLoading] = useState(false);

  const foundPromoCode = selectedPromoCode
    ? promoCodes?.find(
        (code) => code?.id?.toString() === selectedPromoCode?.value?.toString()
      )
    : null;

  const checkout = () => {
    setOrderLoading(true);
    dispatch(
      makeOrder({
        customer: patient?.id,
        phone_number: patient?.phone_number,
        order_items: items?.map((el) => ({
          menu_item: el?.menu_item,
          quantity: el?.quantity,
          variant: el?.variant,
        })),
        grand_total: orderCalculation?.grand_total,
        address:
          selectedShippingAddress && selectedShippingAddress?.id !== "current"
            ? `${
                selectedShippingAddress?.address1
                  ? `${selectedShippingAddress?.address1},`
                  : ""
              } ${
                selectedShippingAddress?.address2
                  ? `${selectedShippingAddress?.address2},`
                  : ""
              } ${
                selectedShippingAddress?.city
                  ? `${selectedShippingAddress?.city},`
                  : ""
              } ${
                selectedShippingAddress?.state
                  ? `${selectedShippingAddress?.state},`
                  : ""
              } ${
                selectedShippingAddress?.zipcode
                  ? selectedShippingAddress?.zipcode
                  : ""
              }`
            : undefined,
        promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
      })
    ).then((res) => {
      if (res) {
        setOrderLoading(false);
        if (res?.type === "makeOrder/fulfilled") {
          setSelectedPromoCode(undefined);
          dispatch(setCard([]));
          setAddressError("");
          openNotificationWithIcon(
            "success",
            "Create Order",
            "Your order has been successfully created."
          );
          setTimeout(() => {
            navigate("/");
          }, 700);
        } else if (res?.type === "makeOrder/rejected") {
          if (
            typeof res?.payload === "object" &&
            !Array.isArray(res?.payload)
          ) {
            for (let key in res?.payload) {
              if (
                res?.payload[key] &&
                res?.payload[key][0] &&
                typeof res?.payload[key][0] === "string"
              ) {
                openNotificationWithIcon("error", key, res?.payload[key]);
                break;
              } else {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Your order has not been created."
                );
                break;
              }
            }
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              "Your order has not been created."
            );
          }
        }
      }
    });
  };

  const reGenerateCaptcha = () => {
    setIsCaptchaLoading(true);
    dispatch(getCaptcha())?.then((res) => {
      if (res?.type === "get_captcha/fulfilled") {
        setCaptcha(res?.payload);
      }
      setIsCaptchaLoading(false);
    });
  };

  const checkoutForUnAuthUser = (params) => {
    setOrderLoading(true);
    dispatch(
      makeOrderForUnAuthUser({
        customer: patient?.id,
        phone_number: patient?.phone_number, // TODO check
        order_items: items?.map((el) => ({
          menu_item: el?.menu_item,
          quantity: el?.quantity,
          variant: el?.variant,
        })),
        grand_total: orderCalculation?.grand_total,
        address:
          selectedShippingAddress && selectedShippingAddress?.id !== "current"
            ? `${
                selectedShippingAddress?.address1
                  ? `${selectedShippingAddress?.address1},`
                  : ""
              } ${
                selectedShippingAddress?.address2
                  ? `${selectedShippingAddress?.address2},`
                  : ""
              } ${
                selectedShippingAddress?.city
                  ? `${selectedShippingAddress?.city},`
                  : ""
              } ${
                selectedShippingAddress?.state
                  ? `${selectedShippingAddress?.state},`
                  : ""
              } ${
                selectedShippingAddress?.zipcode
                  ? selectedShippingAddress?.zipcode
                  : ""
              }`
            : undefined,
        promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
        ...params,
      })
    ).then((res) => {
      if (res) {
        setOrderLoading(false);
        if (res?.type === "makeOrder_for_un_authUser/fulfilled") {
          setSelectedPromoCode(undefined);
          dispatch(setCard([]));
          setAddressError("");
          orderFormRef?.current?.resetFields();
          openNotificationWithIcon(
            "success",
            "Create Order",
            "Your order has been successfully created."
          );
          setTimeout(() => {
            navigate("/");
          }, 700);
        } else if (res?.type === "makeOrder_for_un_authUser/rejected") {
          if (
            typeof res?.payload === "object" &&
            !Array.isArray(res?.payload)
          ) {
            for (let key in res?.payload) {
              if (
                res?.payload[key] &&
                res?.payload[key][0] &&
                typeof res?.payload[key][0] === "string"
              ) {
                openNotificationWithIcon("error", key, res?.payload[key]);
                break;
              } else {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Your order has not been created."
                );
                break;
              }
            }
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              "Your order has not been created."
            );
          }
          orderFormRef?.current?.resetFields();
          reGenerateCaptcha();
        }
      }
    });
  };

  const updateShippingAddress = (params) => {
    if (params?.addressId !== "current") {
      dispatch(
        patchPatientShippingAddress({
          id: params?.id,
          addressId: params?.addressId,
          default: params.default,
        })
      ).then((res) => {
        if (res.type === "patch_patient_shiping_address/fulfilled") {
          setSelectedShippingAddress(initialAddress);
          dispatch(getPatientDetails({ id: params?.id }));
          setIsOnEditMode(false);
        } else if (res.type === "patch_patient_shiping_address/rejected") {
          const error = res?.payload;
          if (!Array.isArray(error) && typeof error === "object") {
            for (let key in error) {
              openNotificationWithIcon("error", key, error[key]);
              break;
            }
          } else {
            openNotificationWithIcon(
              "error",
              "Update Shipping address",
              "Shipping address did not changed"
            );
          }
        }
      });
    } else {
      setIsOnEditMode(false);
    }
  };

  useEffect(() => {
    setInitialAddress({
      id: "current",
      address1: patient?.address ? patient?.address : "",
      state: patient?.state ? patient?.state : "",
      city: patient?.city ? patient?.city : "",
      zipcode: patient?.zipcode ? patient?.zipcode : "",
      address2: patient?.address2 ? patient?.address2 : "",
    });
  }, [
    patient?.address,
    patient?.address2,
    patient?.state,
    patient?.city,
    patient?.zipcode,
  ]);

  useEffect(() => {
    dispatch(getPromoCodes({ page: 1, per_page: 100, status: "active" }));
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated && haveCartCredentials) {
      setIsCaptchaLoading(true);
      dispatch(getCaptcha())?.then((res) => {
        if (res?.type === "get_captcha/fulfilled") {
          setCaptcha(res?.payload);
        }
        setIsCaptchaLoading(false);
      });
    }
  }, [dispatch, isAuthenticated, haveCartCredentials]);

  const getPayementDetailPrice = (price) => {
    if (!isNaN(price)) {
      return Utils?.roundNumber(price);
    }
  };

  const showTaxes = (taxes) => {
    const res = [];
    let i = 0;
    for (let key in taxes) {
      if (key !== "discount_total" && key !== "grand_total") {
        const formattedKey = key
          ?.split("_") // Split key by underscores
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
          ) // Capitalize each word
          ?.join(" ");
        if (key === "promo_code") {
          res?.push(
            <div className="cartOrderDetails-cont__textWrapper" key={i}>
              <Typography.Text
                className="cartOrderDetails-cont__text"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                {formattedKey}
              </Typography.Text>
              <Typography.Text
                className="cartOrderDetails-cont__title"
                style={{
                  color: token?.Typography?.colorPrimaryText,
                }}>
                {taxes[key] || taxes[key] === 0
                  ? `${
                      taxes[key] === 0
                        ? getPayementDetailPrice(taxes[key])
                        : "-" + getPayementDetailPrice(taxes[key])
                    }`
                  : "$0"}
              </Typography.Text>
            </div>
          );
        } else {
          res?.push(
            <div className="cartOrderDetails-cont__textWrapper" key={i}>
              <Typography.Text
                className="cartOrderDetails-cont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {formattedKey}
              </Typography.Text>
              <Typography.Text
                className="cartOrderDetails-cont__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                {taxes[key] || taxes[key] === 0
                  ? getPayementDetailPrice(taxes[key])
                  : "$0"}
              </Typography.Text>
            </div>
          );
        }

        i += 1;
      }
    }
    return res;
  };

  return (
    <>
      <Card
        className="cartOrderDetails-cont"
        style={
          haveCartCredentials
            ? {
                border: "none",
                backgroundColor: token?.Card?.colorBgCard,
              }
            : {
                height: "fit-content",
                border: "none",
                backgroundColor: token?.Card?.colorBgCard,
              }
        }
        styles={{
          body: isMobile
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "16px",
              }
            : {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: "32px",
              },
        }}>
        <div className="cartOrderDetails-cont__promoCodeWrapper">
          <Typography.Text
            className="cartOrderDetails-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Promo Code
          </Typography.Text>
          <Select
            className="cartOrderDetails-cont__promoCodeWrapper__promoInp"
            placeholder="Promo Code"
            value={selectedPromoCode ? selectedPromoCode : undefined}
            options={promoCodes?.map((promoCode) => ({
              label: promoCode?.code ? promoCode?.code : "",
              value: promoCode?.id ? promoCode?.id : "",
            }))}
            allowClear
            onClear={() => {
              setSelectedPromoCode(undefined);
            }}
            onChange={(_, val) => {
              setSelectedPromoCode(val);
            }}
            disabled={orderLoading}
            loading={promoCodesLoading}
          />
          {selectedPromoCode ? (
            <Typography.Text
              className="cartOrderDetails-cont__text"
              style={{
                color: token?.Typography?.colorPrimaryText,
                fontSize: "14px",
              }}
              ellipsis={{
                tooltip: {
                  title: foundPromoCode?.description
                    ? foundPromoCode?.description
                    : "No Description",
                },
              }}>
              {foundPromoCode?.description
                ? foundPromoCode?.description
                : "No Description"}
            </Typography.Text>
          ) : null}
        </div>
        <Divider
          type="horizontal"
          className="cartOrderDetails-cont__divider"
          style={{
            borderColor: token?.Layout?.footerBorderTopColor,
          }}
        />
        <div className="cartOrderDetails-cont__keyValPairWrapper">
          <Typography.Text
            className="cartOrderDetails-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Order Summary
          </Typography.Text>
          {showTaxes(orderCalculation)}
        </div>
        <Divider
          type="horizontal"
          className="cartOrderDetails-cont__divider"
          style={{
            borderColor: token?.Layout?.footerBorderTopColor,
          }}
        />
        {haveCartCredentials ? (
          <div className="cartOrderDetails-cont__shippingAddressCont">
            <div className="cartOrderDetails-cont__textWrapper">
              <Typography.Text
                className="cartOrderDetails-cont__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                Shipping Address
              </Typography.Text>
              {isOnEditMode ? (
                <div style={{ display: "flex", gap: "8px" }}>
                  <Button
                    type="text"
                    size="small"
                    disabled={!initialAddress || loading || error}
                    loading={loading}
                    icon={
                      <CheckCircleOutlined
                        style={{
                          fontSize: "18px",
                          color: token?.icons?.colorPrimaryIcon,
                        }}
                      />
                    }
                    onClick={() => {
                      updateShippingAddress({
                        id: patient.id,
                        addressId: initialAddress?.id,
                        default: true,
                      });
                    }}
                  />
                  <Button
                    type="text"
                    size="small"
                    disabled={loading}
                    icon={
                      <CloseCircleOutlined
                        style={{
                          fontSize: "18px",
                          color: token?.icons?.whiteIcon,
                        }}
                      />
                    }
                    onClick={() => {
                      if (error) {
                        dispatch(resetPatientError());
                      }
                      setIsOnEditMode(false);
                      if (selectedShippingAddress) {
                        setInitialAddress(selectedShippingAddress);
                      } else {
                        setInitialAddress({
                          id: "current",
                          address1: patient?.address ? patient?.address : "",
                          state: patient?.state ? patient?.state : "",
                          city: patient?.city ? patient?.city : "",
                          zipcode: patient?.zipcode ? patient?.zipcode : "",
                          address2: patient?.address2 ? patient?.address2 : "",
                        });
                      }
                    }}
                  />
                </div>
              ) : (
                <Button
                  type="text"
                  size="small"
                  loading={loading}
                  icon={
                    patient?.address ? (
                      <EditOutlined
                        style={{
                          fontSize: "18px",
                          color: token?.icons?.colorPrimaryIcon,
                        }}
                      />
                    ) : (
                      <PlusOutlined
                        style={{
                          fontSize: "18px",
                          color: token?.icons?.colorPrimaryIcon,
                        }}
                      />
                    )
                  }
                  onClick={() => {
                    if (patient?.address) {
                      if (error) {
                        dispatch(resetPatientError());
                      }
                      setIsOnEditMode(true);
                    } else {
                      if (error) {
                        dispatch(resetPatientError());
                      }
                      set_pick_up_point_opened(true);
                    }
                  }}
                />
              )}
            </div>
            {isOnEditMode ? (
              <ShippingSelect
                className="cartOrderDetails-cont__shippingAddressCont__shippingSelect"
                token={token}
                placeholder={"Shipping Address"}
                addBtnText="Add"
                loading={loading}
                options={addresses}
                selectedItem={initialAddress}
                setSelectedItem={setInitialAddress}
                addItem={(details) => {
                  dispatch(
                    postPatientShippingAddress({
                      ...details,
                      id: patient?.id,
                    })
                  ).then((res) => {
                    if (res.type === "post_patient_shiping_address/rejected") {
                      let error = res?.payload;
                      for (let key in error) {
                        openNotificationWithIcon("error", key, error[key]);
                      }
                      setTimeout(() => {
                        dispatch(resetPatientError());
                      }, 2000);
                    }
                  });
                }}
                error={error}
                resetError={() => {
                  dispatch(resetPatientError());
                }}
              />
            ) : addressError ? (
              <Typography.Text
                className="cartOrderDetails-cont__shippingAddressCont__text"
                style={{
                  color: token?.colorError,
                }}>
                {addressError}
              </Typography.Text>
            ) : (
              <Typography.Text className="cartOrderDetails-cont__shippingAddressCont__text">
                {selectedShippingAddress
                  ? `${
                      selectedShippingAddress?.address1
                        ? `${selectedShippingAddress?.address1},`
                        : ""
                    } ${
                      selectedShippingAddress?.address2
                        ? `${selectedShippingAddress?.address2},`
                        : ""
                    } ${
                      selectedShippingAddress?.city
                        ? `${selectedShippingAddress?.city},`
                        : ""
                    } ${
                      selectedShippingAddress?.state
                        ? `${selectedShippingAddress?.state},`
                        : ""
                    } ${
                      selectedShippingAddress?.zipcode
                        ? selectedShippingAddress?.zipcode
                        : ""
                    }`
                  : `${patient?.address ? `${patient?.address},` : ""}
                ${patient?.address2 ? `${patient?.address2},` : ""} 
                ${patient?.city ? `${patient?.city},` : ""}
                ${patient?.state ? `${patient?.state},` : ""}
                ${patient?.zipcode ? patient?.zipcode : ""}`}
              </Typography.Text>
            )}
          </div>
        ) : null}
        {haveCartCredentials ? (
          <Divider
            type="horizontal"
            className="cartOrderDetails-cont__divider"
            style={{
              borderColor: token?.Layout?.footerBorderTopColor,
            }}
          />
        ) : null}
        {captcha && !isAuthenticated && haveCartCredentials ? (
          <>
            <div className="cartOrderDetails-cont__captchaWrapper">
              <Captcha
                captcha={captcha}
                isLoading={isCaptchaLoading}
                setCaptcha={setCaptcha}
                setIsLoading={setIsCaptchaLoading}
              />
              <Form
                className="cartOrderDetails-cont__captchaWrapper__form"
                ref={orderFormRef}
                onFinish={(values) => {
                  checkoutForUnAuthUser({
                    captcha_value: values?.captcha_value
                      ? values?.captcha_value
                      : null,
                    captcha_key: captcha?.captcha_key
                      ? captcha?.captcha_key
                      : null,
                  });
                }}>
                <Form.Item
                  className="cartOrderDetails-cont__captchaWrapper__form__formItem"
                  name="captcha_value"
                  rules={[
                    {
                      required: true,
                      message: "Please enter captcha value",
                    },
                  ]}
                  colon={false}>
                  <Input
                    style={{
                      backgroundColor: token?.Card?.colorBgCard,
                    }}
                    placeholder="Captcha value"
                    disabled={orderLoading || !items?.length}
                  />
                </Form.Item>
              </Form>
            </div>
            <Divider
              type="horizontal"
              className="cartOrderDetails-cont__divider"
              style={{
                borderColor: token?.Layout?.footerBorderTopColor,
              }}
            />
          </>
        ) : null}
        <div className="cartOrderDetails-cont__textWrapper">
          <Typography.Text
            className="cartOrderDetails-cont__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Total
          </Typography.Text>
          <Typography.Text
            className="cartOrderDetails-cont__title"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            {orderCalculation?.grand_total ||
            orderCalculation?.grand_total === 0
              ? getPayementDetailPrice(orderCalculation?.grand_total)
              : "$0"}
          </Typography.Text>
        </div>
        {haveCartCredentials ? (
          <Button
            onClick={() => {
              if (!isAuthenticated) {
                orderFormRef?.current?.submit();
              } else {
                if (patient?.address) {
                  checkout();
                } else {
                  setAddressError(ADDRESS_ERROR);
                }
              }
            }}
            type="primary"
            disabled={!items?.length || addressError}
            loading={orderLoading}>
            Proceed To Checkout
          </Button>
        ) : (
          <Button
            type="primary"
            block={isMobile}
            loading={loading}
            disabled={error ? Object.keys(error)?.length !== 0 : false}
            onClick={() => {
              formRef?.current?.submit();
            }}>
            Next
          </Button>
        )}
      </Card>
      <PickUpPoint
        title="Pick Up Point"
        isOpened={pick_up_point_opened}
        onCancel={() => {
          set_pick_up_point_opened(false);
        }}
        onOk={(params) => {
          dispatch(patchPatient({ ...params, id: patient?.id }))?.then(
            (res) => {
              if (res?.type === "patch_patient/fulfilled") {
                setAddressError("");
              }
            }
          );
        }}
      />
    </>
  );
}
