import React, { useEffect, useState } from "react";
import "./AppHeader.scss";
import { theme, Layout, Typography, Button, Avatar, Badge } from "antd";
import {
  UserOutlined,
  HeartOutlined,
  LogoutOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useLocation, useNavigate } from "react-router";
import { Login, Register, ForgetPassword, ConfirmModal } from "../../modals";
import { useSelector } from "react-redux";
import { logOut, resetAuthError } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { signUp } from "../../features/auth/authActions";
import HeaderDrawer from "./components/HeaderDrawer/HeaderDrawer";
const { Header } = Layout;

export default function AppHeader() {
  const { useToken } = theme;
  const { token } = useToken();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useWindowWidth();
  const location = useLocation();
  const { isAuthenticated, error } = useSelector((state) => state?.auth);
  const { shop, loading: shopLoading } = useSelector(
    (state) => state?.shopDetails
  );
  const { items } = useSelector((state) => state?.card);
  const [isOpened, setIsOpened] = useState(false);
  const [loginOpened, setOpenLogin] = useState(
    pathname === "/login" && !isAuthenticated ? true : false
  );
  const [registerOpened, setOpenRegister] = useState(false);
  const [isForgetPasswordOened, setIsFrogetPasswordOpened] = useState(false);
  const [isLogOutModalOpened, setIsLogOutModalOpened] = useState(false);

  const handleLogOut = () => {
    dispatch(logOut());
    setIsLogOutModalOpened(false);
  };

  const calCulateOrderItemCount = (arr) => {
    let sum = 0;
    arr.forEach((el) => (sum += el?.quantity));
    return sum;
  };

  const orderItemCount = calCulateOrderItemCount(items);

  useEffect(() => {
    if (pathname === "/login" && !isAuthenticated) {
      setOpenLogin(true);
    }
  }, [pathname, isAuthenticated]);

  return (
    <Header
      className="appHeader-cont"
      style={{
        borderBottom: `1px solid ${token?.colorPrimary}`,
      }}>
      <div className="appHeader-cont__flex">
        <div className="appHeader-cont__flex__titleWrapper">
          {isMobile ? (
            <Button
              icon={
                <MenuOutlined
                  style={{
                    color: token?.icons?.whiteIcon,
                    fontSize: "24px",
                  }}
                />
              }
              type="text"
              onClick={() => {
                setIsOpened(true);
              }}
            />
          ) : null}
          {shopLoading ? (
            ""
          ) : shop?.name && shop?.is_name_visible_on_shop ? (
            <Typography.Text
              className="appHeader-cont__flex__titleWrapper__title"
              style={{
                color: token?.Typography?.mainColorText,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}>
              {shop?.name}
            </Typography.Text>
          ) : (
            <Button
              type="text"
              icon={
                <HomeOutlined
                  style={{
                    color: token?.colorPrimary,
                    fontSize: isMobile ? "24px" : "32px",
                  }}
                />
              }
              onClick={() => {
                navigate("/");
              }}
            />
          )}
        </div>
        {isMobile ? (
          orderItemCount ? (
            <Badge
              count={orderItemCount}
              size="small"
              color={token?.colorPrimary}
              styles={{
                root: {
                  marginRight: "15px",
                },
              }}>
              <Button
                icon={
                  <ShoppingCartOutlined
                    style={{
                      color:
                        location?.pathname === "/checkout"
                          ? token?.colorPrimary
                          : token?.icons?.whiteIcon,
                      fontSize: "24px",
                    }}
                  />
                }
                type="text"
                onClick={() => {
                  setIsOpened(false);
                  navigate("checkout");
                }}
              />
            </Badge>
          ) : (
            <Button
              icon={
                <ShoppingCartOutlined
                  style={{
                    color:
                      location?.pathname === "/checkout"
                        ? token?.colorPrimary
                        : token?.icons?.whiteIcon,
                    fontSize: "24px",
                  }}
                />
              }
              type="text"
              onClick={() => {
                setIsOpened(false);
                navigate("checkout");
              }}
            />
          )
        ) : null}
        {isMobile ? (
          <HeaderDrawer
            token={token}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            setOpenLogin={setOpenLogin}
            setIsLogOutModalOpened={setIsLogOutModalOpened}
            setOpenRegister={setOpenRegister}
            isMobile={isMobile}
          />
        ) : (
          <div className="appHeader-cont__flex__filters">
            <div
              className="appHeader-cont__flex__filters__navigationBar"
              style={orderItemCount >= 10 ? { gap: "16px" } : null}>
              <div className="appHeader-cont__flex__filters__navigationBar__btnsWrapper">
                {/* <Button
                  icon={
                    <BellOutlined
                      style={{
                        color: token?.icons?.whiteIcon,
                        fontSize: "24px",
                      }}
                    />
                  }
                  type="text"
                /> */}
                {isAuthenticated ? (
                  <Button
                    icon={
                      <HeartOutlined
                        style={{
                          color:
                            location?.pathname === "/favorites"
                              ? token?.colorPrimary
                              : token?.icons?.whiteIcon,
                          fontSize: "24px",
                        }}
                      />
                    }
                    type="text"
                    onClick={() => {
                      setIsOpened(false);
                      navigate("favorites");
                    }}
                  />
                ) : null}
                {orderItemCount ? (
                  <Badge
                    count={orderItemCount}
                    size="small"
                    color={token?.colorPrimary}>
                    <Button
                      icon={
                        <ShoppingCartOutlined
                          style={{
                            color:
                              location?.pathname === "/checkout"
                                ? token?.colorPrimary
                                : token?.icons?.whiteIcon,
                            fontSize: "24px",
                          }}
                        />
                      }
                      type="text"
                      onClick={() => {
                        setIsOpened(false);
                        navigate("checkout");
                      }}
                    />
                  </Badge>
                ) : (
                  <Button
                    icon={
                      <ShoppingCartOutlined
                        style={{
                          color:
                            location?.pathname === "/checkout"
                              ? token?.colorPrimary
                              : token?.icons?.whiteIcon,
                          fontSize: "24px",
                        }}
                      />
                    }
                    type="text"
                    onClick={() => {
                      setIsOpened(false);
                      navigate("checkout");
                    }}
                  />
                )}
              </div>
              {!isAuthenticated ? (
                <>
                  <div className="appHeader-cont__flex__filters__navigationBar__authWrapper">
                    <Button
                      type="text"
                      onClick={() => {
                        setOpenLogin(true);
                        if (error) {
                          dispatch(resetAuthError());
                        }
                      }}>
                      <Typography.Text
                        style={{
                          color: token?.Typography?.colorPrimaryText,
                        }}>
                        Log in
                      </Typography.Text>
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenRegister(true);
                        if (error) {
                          dispatch(resetAuthError());
                        }
                      }}
                      type="primary">
                      Sign Up
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  className="appHeader-cont__flex__filters__navigationBar__authWrapper"
                  style={{
                    alignItems: "center",
                  }}>
                  <Badge
                    size="small"
                    dot
                    styles={{
                      root: {
                        cursor: "pointer",
                      },
                      indicator: {
                        background: token?.colorPrimary,
                      },
                    }}
                    onClick={() => {
                      navigate("profile");
                    }}>
                    <Avatar
                      shape="square"
                      style={{
                        backgroundColor: token?.Card?.colorBgCard,
                      }}
                      size={24}
                      icon={
                        <UserOutlined
                          style={{
                            color:
                              location?.pathname === "/profile"
                                ? token?.colorPrimary
                                : token?.icons?.whiteIcon,
                            fontSize: "18px",
                          }}
                        />
                      }
                    />
                  </Badge>
                  <Button type="text">
                    <Typography.Text
                      className="appHeader-cont__flex__drawer__body__text"
                      style={{
                        color: token?.colorError,
                      }}
                      onClick={() => {
                        setIsLogOutModalOpened(true);
                      }}>
                      Log Out
                    </Typography.Text>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Login
        isOpened={loginOpened}
        setIsOpened={setOpenLogin}
        setIsRegisterOpened={setOpenRegister}
        setIsFrogetPasswordOpened={setIsFrogetPasswordOpened}
        title={"Login"}
        token={token}
      />
      <Register
        isOpened={registerOpened}
        title="Sign Up"
        onCancel={() => {
          setOpenRegister(false);
        }}
        onOk={(values) => {
          dispatch(signUp(values))?.then((res) => {
            if (res?.type === "sign_up/fulfilled") {
              setTimeout(() => {
                setOpenLogin(true);
              }, 500);
            }
          });
        }}
        token={token}
      />
      <ForgetPassword
        title={"Forgot Password"}
        isOpened={isForgetPasswordOened}
        onCancel={() => {
          setIsFrogetPasswordOpened(false);
        }}
        openLogedInModal={setOpenLogin}
      />
      <ConfirmModal
        isOpened={isLogOutModalOpened}
        title={"Are you sure"}
        subTitle={"You want to log out ?"}
        icon={
          <LogoutOutlined
            style={{ fontSize: "24px", transform: "rotate(180deg)" }}
          />
        }
        onCancel={() => {
          setIsLogOutModalOpened(false);
        }}
        onOk={handleLogOut}
        token={token}
      />
    </Header>
  );
}
